<template>
  <div class="top-offset">
    <!-- <v-row justify="center" align="center" class="pa-10" no-gutters>
      <v-col cols="12" md="6" lg="3" sm="8" xs="6">
          <div ref="printMe">
        <v-card>
            <v-img
            class="white--text"
            :src="logo"
          >
            <v-card-title class="fill-height"
              ><h2 class="black--white">Discount Card</h2>
            </v-card-title>
          </v-img>
          <v-list-item style="background: white;">
            <v-list-item-content>
              <v-img src="@/assets/calzedoniaLogo.png" ></v-img>
              <v-list-item-title class="text-h5 ml-1 black--text">
                Andrej Soldo
              </v-list-item-title>
              <v-list-item-subtitle class="black--text">
                soldo.andrej@gmail.com
              </v-list-item-subtitle>
            </v-list-item-content>
            <div style="background: white;">
              <barcode
                height="40"
                width="2"
                :value="barcodeValue"
                :display-value="displayValue"
                format="ean13"
                background="transparent"
                lineColor="black"
              >
                It is not loading
              </barcode>
            </div>
          </v-list-item>
          <div class="white--text" style="background: #95A5FE;">
            <v-list-item-subtitle class="text-center">
              Calzedonia
            </v-list-item-subtitle>
          </div>
        </v-card>
          </div>
    <v-btn block class="primary" @click="print()">Generate</v-btn>
          
        <img :src="output" width="100%" />
      </v-col>
    </v-row> -->
    <!-- Start Error Dialog -->
      <v-dialog v-model="errorDialog" max-width="500px">
      <v-card>
        <v-alert
        :type="type"
        prominent
        border="left"
        dismissable
        close-text="Close Alert"
        class="ma-0"
      >
        <v-row align="center">
          <v-col class="grow">
            {{errorMessage}}
          </v-col>
          <v-col class="shrink">
            <!-- <v-btn>Take action</v-btn> -->
            <v-btn color="white" text @click="errorDialog = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
        </v-alert>
      </v-card>
    </v-dialog>
    <!-- End Error Dialog -->
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12" lg="12" md="12" justify="center" align="center">
            <v-date-picker
              class="border"
              v-model="picker"
              type="month"
              color="primary">
            </v-date-picker>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12" justify="center" align="center">
          <v-btn color="primary" @click="parseMonth()">
            <v-icon class="mx-2">mdi-page-next</v-icon> Generate and Download
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import VueBarcode from "vue-barcode";
import axios from "axios";

export default {
  name: "DiscountCard",
  // components: {
  //   'barcode': VueBarcode,
  // },
  data() {
    return {
      picker: null,
      pickerMonth: 0,
      barcodeValue: "123456789123",
      output: null,
      displayValue: true,
      background: "transprent",
      logo: require("@/assets/cardDesign.jpg"),
      errorDialog: false,
      errorMessage: "",
      type: ""
    };
  },
  methods: {
    async print() {
      const el = this.$refs.printMe;
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: "dataURL",
        // useCORS: true
        imageSmoothingEnabled: false
      };
      this.output = await this.$html2canvas(el, options);
    },
    async parseMonth() {
      if (this.picker !== null) {
        let date = [];
        date = this.picker.split("-");
        let result = [];
        result = date[1].split("");
        let final = 0;
        if (result[0] == "0") {
          final = result[1];
          console.log(final);
        } else {
          final = date[1];
          console.log(final);
        }
        // console.log(date[0]);
        axios
          .post(
            this.$apiUrl,
            {
              query: `mutation{
            generateInvalidatedCardsCsv(invalidationFetchInput: {month: ${final}, year: ${date[0]}})
            {
              name
              content
              mimetype
            }
          }`,
            },
            {
              headers: {
              Authorization: "Bearer " + await this.$auth.getAccessToken(),
              },
            }
          )
          .then((response) => {
            if(response.data.errors == undefined){
              console.log(
                response.data.data.generateInvalidatedCardsCsv,
                "response"
              );
              let a = document.createElement("a");
              a.href =
                "data:application/csv;base64, " +
                response.data.data.generateInvalidatedCardsCsv.content;
              a.download = `${response.data.data.generateInvalidatedCardsCsv.name}`;
              a.click();
              a.remove();
            }else {
            console.log(response.data.errors, "errors");
            this.errorMessage = response.data.errors[0].message;
            this.type = "error";
            this.errorDialog = true;            
          }
          });
      }
      else{
        // alert("Please select a date! 'eg. Month'");
        this.errorMessage = "Please select a date! 'eg. Month'";
        this.type = "warning";
        this.errorDialog = true;
      }
    },
  },
  created() {
    // setTimeout(() => {
    //   this.print();
    // }, 100);
  },
};
</script>

<style scoped>
.top-offset {
  margin-top: 100px !important;
  width: 100vw;
}
.border {
  border: 1px solid #007bff;
}
</style>
